<template>
  <v-container fluid>
    <div v-if="loadingAnualReport">
      <v-card width="100vw" height="90vh" elevation="0">
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center">
              <v-progress-circular
                :width="4"
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <div>
                {{ statusLoading }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div v-else-if="leadsMktReportIsEmpty">
      <v-card width="100vw" height="90vh" elevation="0">
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center"> </v-col>
            <v-col cols="12" justify="center" align="center">
              <div>
                No se encontraron Leads.
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div v-else>
      <div class="py-5"></div>
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card elevation="0">
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-row justify="start" align="start">
                  <v-col cols="12">
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        class="ma-2 px-10"
                        color="primary"
                        @click="step = 2"
                      >
                        Reporte Histórico
                        <v-icon dark right>
                          mdi-page-last
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col class="px-10" cols="12" sm="6">
                    <v-row justify="center" align="center">
                      <FilterAutocomplete
                        v-if="zonesList.length > 0"
                        v-model="filter"
                        :items="zonesList"
                        label="Filtrar por Desarrollo"
                      />
                    </v-row>
                  </v-col>
                  <v-col class="px-7" cols="12" sm="6">
                    <FilterAutocomplete
                      v-model="howDidFilter"
                      :items="sourcesList"
                      label="Filtrar por Fuente"
                      itemText="name"
                      itemValue="_id"
                    />
                  </v-col>
                </v-row>
                <v-col cols="12" sm="2">
                  <v-btn elevation="0" color="primary" @click="clearAll()">
                    Limpiar filtros
                  </v-btn>
                </v-col>
              </v-col>
              <!-- My new table with my new datas -->
              <v-col cols="12">
                <v-tabs
                  class="my-0"
                  v-model="tab_new"
                  slider-size="4"
                  background-color="transparent"
                  color="cyan lighten-1"
                  grow
                >
                  <v-tab
                    v-for="(year, key) in yearsAvailableInAnualMKT"
                    :key="key"
                    @click="
                      (dataTableInfoCurrent = dataTableInfoAll[year]),
                        (yearSelected = year)
                    "
                  >
                    {{ year }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab_new">
                  <v-tab-item
                    v-for="(year, index) in yearsAvailableInAnualMKT"
                    :key="index"
                  >
                    <v-card elevation="0" class="my-10" flat>
                      <v-col cols="12">
                        <v-row justify="center" align="center">
                          <v-col cols="12" sm="6"> </v-col>
                          <v-col class="px-10" cols="12" sm="6">
                            <v-row>
                              <v-spacer />
                              <!-- :disabled="creatingAnualMktPDF" -->
                              <v-btn
                                text
                                @click="exportPdf(year)"
                                :loading="creatingAnualMktPDF"
                              >
                                Exportar Año {{ year }} a PDF
                                <v-icon>
                                  mdi-file-document-outline
                                </v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-data-table
                        :ref="`anualTable${year}`"
                        hide-default-footer
                        class="elevation-1"
                        :headers="dataTableInfoAll[year].headers"
                        :items="dataTableInfoAll[year].tmpDesserts"
                        :items-per-page="100"
                      >
                        <template v-slot:body="props">
                          <tr
                            v-for="(row, r) in props.items"
                            :key="r"
                            :class="`${row.color} ${row.txtcolor}`"
                          >
                            <td
                              class="pa-2"
                              v-for="(column, c) in props.headers"
                              :key="c"
                            >
                              <div v-if="c === 0">
                                {{ row.name }}
                              </div>
                              <div v-else-if="column.text !== 'Total'">
                                <!-- Aqui entran las columnas de los meses, enero, feb, marzo -->
                                <div
                                  v-for="(data, i) in getAmountByValueNewv2(
                                    row.name,
                                    row.get,
                                    year,
                                    column.text,
                                    row.phase
                                  )"
                                  :key="i"
                                >
                                  <div v-if="row.get === '='">
                                    <span
                                      v-if="row.phase === 'all'"
                                      :class="
                                        data.total === 0 ? 'grey--text' : ''
                                      "
                                    >
                                      {{ data.total }}
                                    </span>
                                    <span
                                      v-else
                                      :class="
                                        data.total === 0
                                          ? 'grey--text'
                                          : 'total-info'
                                      "
                                      @click="
                                        data.total === 0
                                          ? () => {}
                                          : getLeads({
                                              year: year,
                                              month: column.text,
                                              phase: row.phase
                                            })
                                      "
                                    >
                                      {{ data.total }}

                                      <small> ({{ data.avg }}%)</small>
                                    </span>
                                  </div>
                                  <div v-else>
                                    <div
                                      :class="
                                        data.mxm === 0 ? 'grey--text' : ''
                                      "
                                    >
                                      ${{ getThreeNumbers(data.mxm) }} MXN
                                    </div>
                                    <div
                                      :class="
                                        data.usd === 0 ? 'grey--text' : ''
                                      "
                                    >
                                      ${{ getThreeNumbers(data.usd) }} USD
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- Aquí entra la columna del total -->
                              <div v-else>
                                <div
                                  v-for="(data, i) in getTotalByValueNewv2(
                                    row.get,
                                    year,
                                    row.phase
                                  )"
                                  :key="i"
                                >
                                  <div v-if="row.get === '='">
                                    <span
                                      v-if="row.phase === 'all'"
                                      :class="
                                        data.total === 0 ? 'grey--text' : ''
                                      "
                                    >
                                      {{ data.total }}
                                    </span>
                                    <span
                                      v-else
                                      :class="
                                        data.total === 0 ? 'grey--text' : ''
                                      "
                                    >
                                      {{ data.total }}
                                      <small> ({{ data.avg }}%)</small>
                                    </span>
                                  </div>
                                  <div v-else>
                                    <div>
                                      ${{ getThreeNumbers(data.mxm) }} MXN
                                    </div>
                                    <div>
                                      ${{ getThreeNumbers(data.usd) }} USD
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                    <v-card v-if="!loadingAnualReport">
                      <div id="chart">
                        <apexchart
                          ref="myChart"
                          type="line"
                          height="350"
                          :options="chartOptions"
                          :series="chartSeriesComputed"
                        ></apexchart>
                      </div>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              <!-- End my new table with my new datas -->
            </v-row>
          </v-card>
        </v-window-item>
        <v-window-item :value="2">
          <v-row>
            <v-btn text class="ma-2 px-10" color="primary" @click="step = 1">
              <v-icon dark right class="mx-2">
                mdi-page-first
              </v-icon>
              Reporte Anual
            </v-btn>
            <v-spacer></v-spacer>
          </v-row>
          <history-mkt-report-version2 />
        </v-window-item>
      </v-window>
    </div>
    <ListLeadsByReportDetail
      :leads="leads"
      :dialog="dialog"
      :loading="loadingLeadList"
      @setDialog="value => (dialog = value)"
    >
    </ListLeadsByReportDetail>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ListLeadsByReportDetail from "./component/ListLeadsByReportDetail";
import FilterAutocomplete from "./component/FilterAutocomplete.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  components: {
    ListLeadsByReportDetail,
    FilterAutocomplete,
    HistoryMktReportVersion2: () =>
      import("@/components/reports-kpis/HistoryMktReportVersion2.vue")
  },
  data() {
    return {
      leads: [],
      dialog: false,
      loadingLeadList: false,
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false
          },
          events: {
            legendClick: function(chart, seriesIndex) {
              requestAnimationFrame(() => {
                const seriesNames = [
                  "Oportunidades",
                  "Descartadas",
                  "Activas",
                  "Operaciones",
                  "Cerradas"
                ];
                const collapsedSeries = chart.w.globals.collapsedSeriesIndices;
                const isSeriesHidden = collapsedSeries.includes(seriesIndex);
                if (!isSeriesHidden && collapsedSeries.length !== 0) {
                  seriesNames.forEach(name => {
                    chart.showSeries(name);
                  });
                  return;
                }
                seriesNames.forEach((name, index) => {
                  if (index !== seriesIndex) {
                    chart.hideSeries(name);
                  } else {
                    chart.showSeries(name);
                  }
                });
              });
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        title: {
          text: "Gráfica de reporte anual",
          align: "left"
        },
        tooltip: {
          hideEmptySeries: false,
          x: {
            show: true
          },
          y: {
            formatter: function(val) {
              return val === undefined ? val : val.toFixed(0);
            }
          }
        },
        colors: ["#000000", "#9ca3af", "#311b92", "#0d47a1", "#854d0e"],
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        yaxis: {
          labels: {
            formatter: value => (value === undefined ? value : value.toFixed(0))
          }
        },
        xaxis: {
          categories: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
          ]
        },
        legend: {
          position: "top",
          floating: true,
          offsetX: -25,
          offsetY: -10,
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: ["#000000", "#9ca3af", "#311b92", "#0d47a1", "#854d0e"],
            radius: 12,
            customHTML: undefined,
            offsetX: 0,
            offsetY: 0
          },
          onItemClick: {
            toggleDataSeries: false
          }
        }
      },
      step: 1,
      creatingAnualMktPDF: false,
      formatterusd: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }),
      formattermxn: new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN"
      }),
      howDidFilterSelected: null,
      sortListBySelected: null,
      statusLoading: "Obteniendo Leads ...",
      loadingAnualReport: true,
      //
      leadsMktReportIsEmpty: false,
      //
      realEstateList: [],
      filter: [],
      howDidFilter: [],
      // Lista de como nos contacto
      sourcesList: [],
      // Los años disponibles
      yearsAvailableInAnualMKT: [],
      // tabs nuevos
      tab_new: null,
      //
      yearSelected: "",
      //
      clearFiltersAllOn: false,
      //
      headers_new: {},
      desserts_new: {},
      dataTableInfoAll: {},
      dataTableInfoCurrent: {},
      //
      headers: {},
      desserts: {},
      tmpDesserts: [
        {
          name: "Oportunidades",
          get: "=",
          phase: "all",
          class: "mx-2",
          color: "grey lighten-4",
          txtcolor: "grey--text text--darken-4"
        },
        {
          name: "Descartadas",
          get: "=",
          phase: "discarded",
          class: "mx-2 ",
          color: "grey lighten-2",
          txtcolor: "grey--text text--darken-4"
        },
        {
          name: "$ Descartadas",
          get: "$",
          phase: "discarded",
          class: "mx-2 ",
          color: "grey lighten-2",
          txtcolor: "grey--text text--darken-4"
        },
        {
          name: "Activas",
          get: "=",
          phase: "active",
          class: "mx-2 ",
          color: "purple lighten-5",
          txtcolor: "deep-purple--text text--darken-4"
        },
        {
          name: "Presupuesto",
          get: "$",
          phase: "active",
          class: "mx-2",
          color: "purple lighten-5",
          txtcolor: "deep-purple--text text--darken-4"
        },
        {
          name: "Operaciones",
          get: "=",
          phase: "in-operation",
          class: "mx-2 ",
          color: "blue lighten-5",
          txtcolor: "blue--text text--darken-4"
        },
        {
          name: "Posibilidad de ingreso",
          get: "$",
          phase: "in-operation",
          class: "mx-2 ",
          color: "blue lighten-5",
          txtcolor: "blue--text text--darken-4"
        },
        {
          name: "Cerradas",
          get: "=",
          phase: "finished",
          class: "mx-2 ",
          color: "yellow lighten-5",
          txtcolor: "brown--text text--darken-4"
        },
        {
          name: "Ventas",
          get: "$",
          phase: "finished",
          class: "mx-2",
          color: "yellow lighten-5",
          txtcolor: "brown--text text--darken-4"
        }
      ],
      monthNamesAndIndex: [
        {
          name: "Enero",
          number_month: 1
        },
        {
          name: "Febrero",
          number_month: 2
        },
        {
          name: "Marzo",
          number_month: 3
        },
        {
          name: "Abril",
          number_month: 4
        },
        {
          name: "Mayo",
          number_month: 5
        },
        {
          name: "Junio",
          number_month: 6
        },
        {
          name: "Julio",
          number_month: 7
        },
        {
          name: "Agosto",
          number_month: 8
        },
        {
          name: "Septiembre",
          number_month: 9
        },
        {
          name: "Octubre",
          number_month: 10
        },
        {
          name: "Noviembre",
          number_month: 11
        },
        {
          name: "Diciembre",
          number_month: 12
        }
      ]
    };
  },
  computed: {
    ...mapState({
      // Store de filter
      // Se encuentran los sources
      filters: state => state.leads.filters,
      leadListByAnualMKTMonth: state => state.reports.leadListByAnualMKTMonth,
      leadsList: state => state.reports.leadListByComertialReport
    }),
    zonesList() {
      return this.filters.zones;
    },
    chartSeriesComputed() {
      const year = this.yearSelected;
      const columnsTableByYear = this.dataTableInfoAll[year];
      const body = [];
      for (const [r, row] of Object.entries(columnsTableByYear.tmpDesserts)) {
        if (row.get === "=") {
          const localRowBody = {};
          localRowBody["0"] = row.name;
          localRowBody["info"] = [];
          for (const [c, column] of Object.entries(
            columnsTableByYear.headers
          )) {
            if (c > 0 && c < 13) {
              for (const data of this.getAmountByValueNewv2(
                row.name,
                row.get,
                year,
                column.text,
                row.phase
              )) {
                if (r === "0" || row.get === "=") {
                  localRowBody["info"].push(data.total);
                }
              }
            }
          }
          body.push(localRowBody);
        }
      }
      const series = [
        {
          name: "Oportunidades",
          data: body[0].info,
          id: 1
        },
        {
          name: "Descartadas",
          data: body[1].info,
          id: 2
        },
        {
          name: "Activas",
          data: body[2].info,
          id: 1
        },
        {
          name: "Operaciones",
          data: body[3].info,
          id: 2
        },
        {
          name: "Cerradas",
          data: body[4].info,
          id: 1
        }
      ];

      return series;
    }
  },
  watch: {
    filter() {
      if (this.clearFiltersAllOn) return;
      this.getAnualMktReportWithFilters();
    },
    howDidFilter() {
      if (this.clearFiltersAllOn) return;
      this.getAnualMktReportWithFilters();
    }
  },
  async mounted() {
    this.loadingAnualReport = true;
    await this.fetchListLeadsInAnualMKT({ groupBy: "month" });
    this.yearsAvailableInAnualMKTMethod();
    // Traemos a todos los sources del vuex
    this.getTrafficData();
    this.loadingAnualReport = false;
  },
  methods: {
    ...mapActions({
      fecthReportList: "reports/fecthReportList",
      createReportCreateColumn: "reports/createReportCreateColumn",
      updateReportUpdateColumn: "reports/updateReportUpdateColumn",
      fetchListLeadsInAnualMKT: "reports/fetchListLeadsInAnualMKT",
      getListLeads: "reports/fetchListLeadsByDetailReport"
    }),
    //
    async getLeads(data) {
      this.loadingLeadList = true;
      this.dialog = true;
      await this.getListLeads({
        year: data.year,
        month: this.monthNamesAndIndex.find(month => month.name === data.month)
          .number_month,
        phase: data.phase,
        ...this.handlerFilters(),
        typeReport: "anualMkt"
      });
      this.leads = this.leadsList;
      this.loadingLeadList = false;
    },
    getThreeNumbers(number) {
      if (number === undefined || number === 0) {
        return 0;
      } else {
        const a = number.toString();
        const res = a.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return res;
      }
    },
    //
    // Para obtener los meses es necesario un metodo
    // Aunque deberia se un metodo computado
    // Un metodo computado no nos permitira modificar la data
    yearsAvailableInAnualMKTMethod() {
      // Esto retornara los años que se encuentren la respuesta
      // e. ['2023', '2024']
      const years = Object.keys(this.leadListByAnualMKTMonth);
      if (years.length === 0) {
        years.push("2024");
        this.yearSelected = "2024";
        this.yearsAvailableInAnualMKT = years;
        this.setDataTableInfoAll(true);
      } else {
        this.yearSelected = years[years.length - 1];
        this.yearsAvailableInAnualMKT = years;
        this.setDataTableInfoAll(false);
      }
      // El año a visualizar siempre sera el actual
      // debido a que la repuesta devuelve como ultimo la fecha actual
      this.tab_new = years.length - 1;
    },
    //
    //
    setDataTableInfoAll(noData) {
      const tmpDesserts = this.tmpDesserts;
      let dataAnualMKT;
      if (noData) {
        dataAnualMKT = { 2024: "" };
      } else {
        dataAnualMKT = this.leadListByAnualMKTMonth;
      }
      for (let [year, months] of Object.entries(dataAnualMKT)) {
        const { headers, desserts } = this.getHeadersNew(months, year);
        // Este metodo es necesario, para que sea reactivo las propiedades del objeto
        this.$set(this.dataTableInfoAll, `${year}`, {
          year,
          headers,
          desserts,
          tmpDesserts
        });
      }
    },
    //
    getTotalAmountNew(month) {
      let total = 0;
      let totalbudgetMXN = 0;
      let totalbudgetUSD = 0;
      for (const key in month) {
        total = total + month[key].total;
        totalbudgetMXN = totalbudgetMXN + month[key].budgetMXN;
        totalbudgetUSD = totalbudgetUSD + month[key].budgetUSD;
      }
      return { total, totalbudgetMXN, totalbudgetUSD };
    },
    //
    //
    getTotalByValueNewv2(get, year, phase) {
      const tableCurrentByYear = this.dataTableInfoAll[year].desserts;
      let totalAll = 0;
      let totalbudgetMXNAll = 0;
      let totalbudgetUSDAll = 0;
      let totalInMonth = 0;
      let totalInMonthMXM = 0;
      let totalInMonthUSD = 0;

      tableCurrentByYear.forEach(element => {
        if (element.monthData != undefined) {
          const {
            total,
            totalbudgetMXN,
            totalbudgetUSD
          } = this.getTotalAmountNew(element.monthData);
          totalAll = totalAll + total;
          totalbudgetMXNAll = totalbudgetMXNAll + totalbudgetMXN;
          totalbudgetUSDAll = totalbudgetUSDAll + totalbudgetUSD;
        }
      });
      if (phase === "all") {
        return [
          {
            total: totalAll,
            avg: 100,
            mxm: totalbudgetMXNAll,
            usd: totalbudgetUSDAll
          }
        ];
      } else {
        tableCurrentByYear.forEach(element => {
          if (element.monthData != undefined) {
            const monthWithPhase = element.monthData[phase];
            if (monthWithPhase != undefined) {
              totalInMonth = totalInMonth + monthWithPhase.total;
              totalInMonthMXM = totalInMonthMXM + monthWithPhase.budgetMXN;
              totalInMonthUSD = totalInMonthUSD + monthWithPhase.budgetUSD;
            }
          }
        });
        return [
          {
            total: totalInMonth,
            avg: ((totalInMonth * 100) / totalAll).toFixed(1),
            mxm: totalInMonthMXM,
            usd: totalInMonthUSD
          }
        ];
      }
    },
    //
    getAmountByValueNewv2(rowName, get, year, month, phase) {
      // rowName -> Lead Reales, Descartados, Activos
      // get -> =, $
      // year -> 2024, 2023
      // month -> Enero, Febrero, Marzo, Abril
      // phase -> all, discarded, active, in-operation, finished
      const tableCurrentByYear = this.dataTableInfoAll[year].desserts;
      let tableCurrentByMonth;
      tableCurrentByYear.forEach(element => {
        if (element.nameMonth === month) {
          tableCurrentByMonth = element.monthData;
        }
      });
      if (tableCurrentByMonth != undefined || tableCurrentByMonth != null) {
        const {
          total,
          totalbudgetMXN,
          totalbudgetUSD
        } = this.getTotalAmountNew(tableCurrentByMonth);
        if (phase === "all") {
          return [
            {
              total: total,
              avg: 100,
              mxm: totalbudgetMXN,
              usd: totalbudgetUSD
            }
          ];
        } else {
          const monthWithPhase = tableCurrentByMonth[phase];
          if (monthWithPhase != undefined) {
            return [
              {
                total: monthWithPhase.total,
                avg: ((monthWithPhase.total * 100) / total).toFixed(1),
                mxm: monthWithPhase.budgetMXN,
                usd: monthWithPhase.budgetUSD
              }
            ];
          } else {
            return [
              {
                total: 0,
                avg: 0,
                mxm: 0,
                usd: 0
              }
            ];
          }
        }
      } else {
        return [
          {
            total: 0,
            avg: 0,
            mxm: 0,
            usd: 0
          }
        ];
      }
    },
    //
    //
    getHeadersNew(months, year) {
      const headers = [];
      let desserts = [];
      // Se le inserta la primer columna del año
      headers.push({
        text: year,
        value: "name",
        class: "ma-0 px-2 py-8 white--text blue-grey darken-4",
        icon: "",
        sortable: false
      });
      for (let i = 0; i < this.monthNamesAndIndex.length; i++) {
        const monthNumber = (i + 1).toString();
        const nameMonth = this.monthNamesAndIndex[i].name;
        const monthData = months[monthNumber];
        headers.push({
          text: nameMonth,
          value: nameMonth,
          class: "ma-0 px-2 white--text blue-grey darken-4",
          icon: "",
          sortable: false
        });
        desserts.push({ nameMonth, monthData });
      }
      //Se le inserta la ultima columna del total
      headers.push({
        text: "Total",
        value: "total",
        class: "ma-0 px-2 white--text blue-grey darken-4",
        icon: "",
        sortable: false
      });
      return { headers, desserts };
    },
    //
    fillSourceList() {
      for (let i = 0; i < this.filters.sources.length; i++) {
        this.sourcesList.push(this.filters.sources[i].source);
      }
    },
    //
    // Para traer los sources del store de vuex
    getTrafficData() {
      //Si this.filters no existe o es null no hace nada
      if (!this.filters) return;
      //Si hay sources llama a la funcion para llenar la lista
      if (this.filters.sources) this.fillSourceList();
    },
    async getAnualMktReportWithFilters() {
      await this.fetchListLeadsInAnualMKT(this.handlerFilters());
      this.yearsAvailableInAnualMKTMethod();
    },
    async clearAll() {
      if (this.filter.length === 0 && this.howDidFilter.length === 0) return;
      this.clearFiltersAllOn = true;
      this.filter = [];
      this.howDidFilter = [];
      await this.getAnualMktReportWithFilters();
      this.clearFiltersAllOn = false;
    },
    getChartAmountByMonth() {
      const year = this.yearSelected;
      const columnsTableByYear = this.dataTableInfoAll[year];
      const body = [];
      for (const [r, row] of Object.entries(columnsTableByYear.tmpDesserts)) {
        if (row.get === "=") {
          const localRowBody = {};
          localRowBody["0"] = row.name;
          localRowBody["info"] = [];
          for (const [c, column] of Object.entries(
            columnsTableByYear.headers
          )) {
            if (c > 0 && c < 13) {
              for (const data of this.getAmountByValueNewv2(
                row.name,
                row.get,
                year,
                column.text,
                row.phase
              )) {
                if (r === "0" || row.get === "=") {
                  localRowBody["info"].push(data.total);
                }
              }
            }
          }
          body.push(localRowBody);
        }
      }
      return body;
    },
    exportPdf(year) {
      const columnsTableByYear = this.dataTableInfoAll[year];
      const body = [];
      //v-for="(row, r) in props.items"
      //v-for="(column, c) in props.headers"
      for (const [r, row] of Object.entries(columnsTableByYear.tmpDesserts)) {
        const localRowBody = {};
        localRowBody["0"] = row.name;
        for (const [c, column] of Object.entries(columnsTableByYear.headers)) {
          if (c === "13") {
            //v-for="(data, i) in getTotalByValueNewv2(row.get, year, row.phase)"
            for (const data of this.getTotalByValueNewv2(
              row.get,
              year,
              row.phase
            )) {
              if (r === "0" || row.get === "=") {
                localRowBody[c] = `${data.total}`;
              } else {
                const info = `$${this.getThreeNumbers(
                  data.mxm
                )} MXN\n$${this.getThreeNumbers(data.usd)} USD`;
                localRowBody[c] = info;
              }
            }
          } else if (c > 0 && c < 13) {
            for (const data of this.getAmountByValueNewv2(
              row.name,
              row.get,
              year,
              column.text,
              row.phase
            )) {
              if (r === "0" || row.get === "=") {
                localRowBody[c] = `${data.total}`;
              } else {
                const info = `$${this.getThreeNumbers(
                  data.mxm
                )} MXN\n$${this.getThreeNumbers(data.usd)} USD`;
                localRowBody[c] = info;
              }
            }
          }
        }
        body.push(localRowBody);
      }
      //v-for="(data, i) in getAmountByValueNewv2(row.name, row.get, year, column.text, row.phase )"
      var columns = [
        {
          title: year,
          dataKey: "0"
        },
        {
          title: "Enero",
          dataKey: "1"
        },
        {
          title: "Febrero",
          dataKey: "2"
        },
        {
          title: "Marzo",
          dataKey: "3"
        },
        {
          title: "Abril",
          dataKey: "4"
        },
        {
          title: "Mayo",
          dataKey: "5"
        },
        {
          title: "Junio",
          dataKey: "6"
        },
        {
          title: "Julio",
          dataKey: "7"
        },
        {
          title: "Agosto",
          dataKey: "8"
        },
        {
          title: "Septiembre",
          dataKey: "9"
        },
        {
          title: "Octubre",
          dataKey: "10"
        },
        {
          title: "Noviembre",
          dataKey: "11"
        },
        {
          title: "Diciembre",
          dataKey: "12"
        },
        {
          title: "Total",
          dataKey: "13"
        }
      ];
      var doc = jsPDF("l", "mm", [677.3, 381]);
      doc.setFontSize(30);
      doc.text(`Reporte Anual Marketing ${year}`, 20, 20);
      doc.autoTable({
        headStyles: {
          fillColor: [38, 50, 56],
          textColor: [255, 255, 255],
          cellPadding: { top: 8, right: 2, bottom: 8, left: 2 }
        },
        styles: {
          fontSize: 15
        },
        margin: { top: 40 },
        columns: columns,
        body: body
      });
      doc.save("table.pdf");
    },
    handlerFilters() {
      const localZone = this.filter;
      const localSources = this.howDidFilter;
      const filtersMkt = {};
      filtersMkt.groupBy = "month";
      if (localZone.length > 0) {
        filtersMkt.zones = localZone;
      }
      if (localSources.length > 0) {
        filtersMkt.sources = localSources;
      }
      return filtersMkt;
    }
  }
};
</script>
<style scoped>
.v-card-tabs {
  padding: 10px;
  background-color: #ffffff !important;
  border: 2px solid rgb(27, 26, 26) !important;
}
</style>
